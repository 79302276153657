<template>
  <div class="container">
    <form v-if="isVersionMatching" v-stream:submit="submit$">
      <text-input
        v-focus
        required
        v-model="user.username"
        type="text"
        name="username"
        label="Email Address"
        autocomplete="username"
        id="username"
        placeholder="Email Address"
        :maxLength="450"
      />
      <div id="passwordInput" class="password-input flex-grow-1 w-full position-relative">
        <text-input
          required
          v-model="user.password"
          :type="showPassword ? 'text' : 'password'"
          name="password"
          label="Password"
          autocomplete="current-password"
          mode="password"
          id="password"
          placeholder="Password"
        />
        <icon-button
          @click="toggleShowPassword"
          id="showPassword"
          role="button"
          :icon="showPassword ? 'eye-slash' : 'eye'"
          v-tooltip="`${showPassword ? 'Hide' : 'Show'} password`"
        />
      </div>
      <p id="error" v-if="isCapsLockOn" class="text-danger mt-2">Caps lock is enabled.</p>
      <div class="d-flex flex-column justify-content-center align-items-center mt-2">
        <div class="error text-center mt-2 mb-0">
          <p id="error" v-if="error" class="text-danger">{{ error }}</p>
          <loader size="small" class="mx-auto" v-else-if="loading"></loader>
          <div class="text-sm">{{ loadingMsg }}</div>
        </div>
        <router-link :to="{ name: 'forgotPassword' }" class="mx-auto my-2 align-self-center" href
          >Forgot Password?</router-link
        >
        <button :disabled="loading" type="submit" class="mx-auto my-2 btn btn-primary">
          Submit
        </button>
      </div>
    </form>
    <div v-else class="text-center">
      <h1 class="mt-2">IntelliPath Pro</h1>
      <p class="text-danger">A new version is available, please wait...</p>
      <p><loader size="small" class="mx-auto"></loader></p>
    </div>
  </div>
</template>

<script>
import { catchError, filter, mergeMap, tap, withLatestFrom } from "rxjs/operators";
import { mapActions, mapState, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import { createLogItem, validatorMsgMapBase } from "../../../modules/helpers";
import auditLog from "../../../services/AuditLog";
import TextInput from "@/components/common/TextInput.vue";
import { fromEvent } from "rxjs";
import Loader from "@/components/common/Loader.vue";
import LogRocket from "logrocket";
import LogrocketFuzzySanitizer from "logrocket-fuzzy-search-sanitizer";
import { version } from "../../../../package.json";
import { detectAnyAdblocker } from "vue-adblock-detector";
import eventBus, { OPEN_STAGING_POPUP, fromBusEvent } from "@/modules/eventBus";
import { installWaitingServiceWorker } from "@/registerServiceWorker";
import IconButton from "@/components/common/IconButton.vue";
const PACKAGE_VERSION = version;
const IS_PRODUCTION = process.env.NODE_ENV === "production";

export default {
  components: { TextInput, Loader, IconButton },
  name: "Login",
  props: {
    redirect: String
  },
  metaInfo: {
    title: "Welcome to IntelliPath Pro"
  },
  created() {
    localStorage.setItem("REQUESTING_SHARED_CREDENTIALS", Date.now().toString());
    localStorage.removeItem("REQUESTING_SHARED_CREDENTIALS");
  },
  mounted() {
    this.getSystemVersion();
    window.addEventListener("keyup", this.handleKeyUp);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.handleKeyUp);
  },
  data() {
    return {
      user: { username: "", password: "" },
      loading: false,
      error: "",
      loadingMsg: "",
      isModalSelectLab: false,
      userlabId: null,
      isVersionMatching: true,
      updatePending: false,
      systemInfo: {},
      showPassword: false,
      isCapsLockOn: false
    };
  },
  validations: {
    user: {
      username: {
        required
      },
      password: {
        required
      }
    }
  },
  domStreams: ["submit$", "pinRequest$"],
  subscriptions() {
    const submit$ = this.submit$.pipe(
      tap(({ event }) => event.preventDefault()),
      filter(() => {
        this.$v.$touch();
        return !this.$v.$invalid;
      })
    );

    const login$ = submit$.pipe(
      mergeMap(async () => {
        return this.loadApp(this.user);
      }),
      catchError(error => {
        this.loading = false;
        this.loadingMsg = "";
        if (error.response?.data) {
          if (this.isCapsLockOn && error.response.data.includes("incorrect")) {
            this.error = "Caps lock is on";
          } else {
            this.error = error.response.data;
          }
        }
        return login$;
      })
    );
    const swUpdated$ = fromBusEvent("swUpdated").pipe(tap(() => installWaitingServiceWorker()));
    const controllerChange$ = fromEvent(navigator.serviceWorker, "controllerchange").pipe(
      tap(() => {
        window.location.reload();
      })
    );
    const swUpdateFound$ = fromBusEvent("swUpdateFound").pipe(
      tap(() => {
        this.isVersionMatching = false;
      }),
      withLatestFrom(swUpdated$),

      withLatestFrom(controllerChange$)
    );

    return {
      login$,
      swUpdateFound$,
      receiveCredentials$: fromEvent(window, "storage").pipe(
        filter(({ key, newValue }) => key === "CREDENTIAL_SHARING" && newValue),
        tap(({ newValue }) => {
          try {
            const { token, state } = JSON.parse(newValue);
            if (token) {
              window.sessionStorage.setItem("token", token);
              this.$store.commit("RESTORE_SHARED_SESSION", JSON.parse(state));
              if (this.redirect) {
                this.$router.push(this.redirect);
              } else {
                this.$router.push("/");
              }
            }
          } catch (error) {
            window.notify("Couldn't load session details.", "error", 5000, ".layout-main");
          }
        })
      )
    };
  },
  computed: {
    ...mapState(["currentUser", "currentLab", "availableLabs"]),
    ...mapGetters(["mustSelectLab"]),
    validatorMsgMap() {
      return {
        ...validatorMsgMapBase,
        nonAlpha: "Must include at least one none alpha numeric character.",
        minLength: "Must be at least 8 characters.",
        sameAs: "Must match the password field."
      };
    },
    labId: {
      get() {
        return this.currentLab;
      },
      set(value) {
        return this.load(value);
      }
    },
    stage() {
      return process.env.VUE_APP_STAGE;
    }
  },
  methods: {
    ...mapActions(["authenticate", "setCurrentLab"]),
    async loadApp(user) {
      this.error = null;
      this.loading = true;
      this.loadingMsg = "Gathering resources...";
      const response = await this.authenticate(user);
      if (response.settings) {
        const { userInStaging } = JSON.parse(response.settings);
        if (userInStaging && !process.env.VUE_APP_STAGE) {
          eventBus.$emit(OPEN_STAGING_POPUP);
          this.loading = false;
          return;
        }
      }
      const { userMustProvidePin, userMustChangePassword, labs } = response;
      await this.$store.dispatch("setAvailableLabs", labs);
      if (userMustProvidePin) {
        return this.$router.push({
          name: "SecurityCode",
          query: {
            redirect: this.redirect
          }
        });
      }
      if (userMustChangePassword) {
        return this.$router.push({
          name: "ExpiredPassword",
          query: {
            redirect: this.redirect
          }
        });
      }
      if (this.mustSelectLab) {
        return;
      }
      this.loadingMsg = "Fetching lab data..";
      this.loadingMsg = "Gathering lab macros..";
      this.loadingMsg = "";
      await auditLog.insertLogMessage({ ...createLogItem({}, 16), comments: "Login" }).catch();
      if (response?.recordLogRocket) {
        const hasAdBlocker = await this.checkAdBlocker();
        if (hasAdBlocker) {
          window.alert(
            "An add blocker is preventing automatic LogRocket recording. Please allow 'www.intellipathpro.com' in the ad blocker and log in again."
          );
        } else {
          await this.handleStartRecording();
        }
      }
      this.$router.push({ name: "Home" });
    },
    async getSystemVersion() {
      if (this.isVersionMatching && IS_PRODUCTION) {
        const localVersion = localStorage.getItem("version");
        if (localVersion && localVersion !== PACKAGE_VERSION) {
          console.log("Mismatch between local and package versions", localVersion, PACKAGE_VERSION);
          localStorage.setItem("version", PACKAGE_VERSION);
          window.alert(`The application has been updated to version ${PACKAGE_VERSION}`);
        } else {
          localStorage.setItem("version", PACKAGE_VERSION);
        }
      }
    },
    async handleStartRecording() {
      this.$store.commit("sessionDetails/setIsRecordingLogRocket", true);
      const privateFieldNames = [
        "password",
        "patient",
        "patientSSN",
        "ssn",
        "dob",
        "patientDOB",
        "patientDob",
        "patientDateOfBirth",
        "dateOfBirth",
        "birth",
        "patientMRN",
        "mrn",
        "patientName",
        "name",
        "patientFirstName",
        "patientMiddleName",
        "patientLastName",
        "patientMaidenName",
        "accountNumber",
        "acctNumber",
        "patientAccountNumber",
        "refNumber",
        "alternateId",
        "address1",
        "address2",
        "addressLine1",
        "addressLine2",
        "line1",
        "line2",
        "address",
        "phone",
        "email",
        "groupNumber",
        "guarantor",
        "policyNumber",
        "comments",
        "patientFieldContains",
        "ipAddress",
        "pdf"
      ];
      const { requestSanitizer, responseSanitizer } = LogrocketFuzzySanitizer.setup([
        ...privateFieldNames
      ]);
      const logrocketApiKey = process.env.VUE_APP_LOGROCKET_API_KEY;
      try {
        LogRocket.init(logrocketApiKey, {
          release: version,
          network: {
            requestSanitizer,
            responseSanitizer
          }
        });
        LogRocket.identify(this.currentUser.id, {
          name: this.currentUser.displayName,
          email: this.currentUser.email
        });
        window.notify("Your session is being recorded by LogRocket.", "success", 5000);
        console.log("Started LogRocket recording.");
      } catch (error) {
        window.notify("Error starting LogRocket recording.", "error", 5000);
      }
    },
    async checkAdBlocker() {
      const detected = await detectAnyAdblocker();
      return detected;
    },
    toggleShowPassword() {
      this.showPassword = !this.showPassword;
    },
    handleKeyUp(event) {
      this.isCapsLockOn = event.getModifierState("CapsLock");
    }
  }
};
</script>

<style lang="scss" scoped>
.pin-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
.modal-heading {
  margin-top: 20px;
  margin-left: 10px;
}
#showPassword {
  position: absolute;
  right: 0px;
  top: 32%;
  cursor: pointer;
}
</style>
